import axios from 'axios'
import { Notification, Message , Loading} from 'element-ui'
import { tansParams } from '@/utils/errorCode'
import errorCode from '@/utils/errorCode'
import cache from '@/plugins/cache'
import router from "@/router";
// 设置响应拦截器
let isRefreshing = false;
let requests = [];
let downloadLoadingInstance;


axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 对应国际化资源文件后缀
axios.defaults.headers['Content-Language'] = 'zh_CN'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 10000
})

// request拦截器
service.interceptors.request.use(config => {
  
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  // 是否需要防止数据重复提交
  const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
  
  if (!isToken) {
    config.headers['authorization'] = 'bearer '+ window.sessionStorage.getItem('accessToken')
    config.headers['refreshToken'] = 'bearer '+ window.sessionStorage.getItem('refreshToken')
  }
  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    let url = config.url + '?' + tansParams(config.params);
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  }
  if (!isRepeatSubmit && (config.method === 'post' || config.method === 'put')) {
    const requestObj = {
      url: config.url,
      data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
      time: new Date().getTime()
    }
    const sessionObj = cache.session.getJSON('sessionObj')
    if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
      cache.session.setJSON('sessionObj', requestObj)
    } else {
      const s_url = sessionObj.url;                  // 请求地址
      const s_data = sessionObj.data;                // 请求数据
      const s_time = sessionObj.time;                // 请求时间
      const interval = 1000;                         // 间隔时间(ms)，小于此时间视为重复提交
      if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url) {
        const message = '数据正在处理，请勿重复提交';
        Message.error({message: message})
        return Promise.reject(new Error(message))
      } else {
        cache.session.setJSON('sessionObj', requestObj)
      }
    }
  }
  return config
}, error => {
    console.log(error)
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
      // 未设置状态码则默认成功状态
      const code = res.status;
      const respCode = res.data.code
      // 获取错误信息
      const msg = errorCode[respCode] || errorCode[code] || res.data.msg || errorCode['default']
      // 二进制数据则直接返回
      if (res.request.responseType ===  'blob' || res.request.responseType ===  'arraybuffer') {
        return res
      }
      if (code !== 200) {
        Notification.error({ title: msg })
        return Promise.reject('error')
      }
      if (respCode !== 200) {
        Notification.error({ title: msg })
        return Promise.reject('error')
      }
      return res.data
    },
  error => {
    if (error.response.status === 401) {
      if (!isRefreshing) {
        isRefreshing = true;
        // 发起刷新 token 的请求
        const refreshTokenRequest = service({
          url: process.env.VUE_APP_BASE_API+`/oauth/oauth/token`,
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          params: {
            grant_type: 'refresh_token',
            refresh_token: window.sessionStorage.getItem('refreshToken'),
            client_id: 'pc',
            client_secret: '123456',
          },
        });
        // 将刷新 token 的请求添加到请求队列中
        const retryRequest = new Promise((resolve, reject) => {
          requests.push({
            originalRequest: error.config,
            resolve,
            reject,
          });
        });
        // 等待刷新 token 的请求完成
        return refreshTokenRequest.then(response => {
              window.sessionStorage.setItem('accessToken', response.data.access_token);
              window.sessionStorage.setItem('refreshToken', response.data.refresh_token);
              // 重新发起所有失败的请求，并将结果返回给调用方
              requests.forEach(({ originalRequest, resolve, reject }) => {
                service(originalRequest).then(resolve).catch(reject);
              });
              requests = [];
              return retryRequest;
            }).catch(refreshError => {
              window.sessionStorage.removeItem('accessToken');
              window.sessionStorage.removeItem('refreshToken');
              router.replace('/')
              return Promise.reject(refreshError); // 将刷新 token 失败的错误传递给调用方
            }).finally(() => {
              isRefreshing = false;
            });
      } else {
        if ("/oauth/oauth/token"===error.config.url){
          window.sessionStorage.removeItem('accessToken');
          window.sessionStorage.removeItem('refreshToken');
          Message.error({message: '刷新token已过期，请重新登录'})
          router.replace('/')
          return; // 将刷新 token 失败的错误传递给调用方
        }
        // 将请求添加到请求队列中
        const retryRequest = new Promise((resolve, reject) => {
          requests.push({
            originalRequest: error.config,
            resolve,
            reject,
          });
        });
        // 返回等待结果的 Promise
        return retryRequest;
      }
    }
    else if(error.response.status===402){
      window.sessionStorage.removeItem('accessToken');
      window.sessionStorage.removeItem('refreshToken');
      router.replace('/')
    }
    else if(error.response.status===502||error.response.status===503){
      Message({ message: '未访问到服务器', type: 'error', duration: 5 * 1000 })
    }
    else if(error.response.status===403){
      Message({ message: error.response.data.error_description, type: 'error', duration: 5 * 1000 })
    }
    return Promise.reject(error);
  }
)
export default service

export function downloadGet(url) {
  downloadLoadingInstance = Loading.service({ text: "正在下载数据，请稍候", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)", })
  return service.get(url, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob',
  }).then(async (res) => {
    down(res)
  }).catch(() => {
    Message.error('下载文件出现错误，请联系管理员！')
    downloadLoadingInstance.close();
  });
}

export function downloadPost(url, data, config) {
  downloadLoadingInstance = Loading.service({ text: "正在下载数据，请稍候", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)", })
  return service.post(url, data, {
    headers: { 'Content-Type': 'application/json; charset=utf-8' },
    responseType: 'blob',
    ...config
  }).then(res=>{
    down(res)
  }).catch(() => {
    this.$message.error("网络错误!");
    downloadLoadingInstance.close();
  });
}

function down(res){
  if (!res.data) return
  // 文件类型
  let blob = new Blob([res.data], { type: 'application/vnd.ms-excel;charset=utf-8' })
  // 从response的headers中获取filename, 后端	 response.setHeader("Content-disposition", "attachment;filename=" + fileName + ".xlsx") 设置的文件名;
  let filename = window.decodeURI(res.headers['content-disposition'].split('=')[1])
  // 创建下载链接
  let url = window.URL.createObjectURL(blob)
  // 赋值给a标签的href属性
  let aLink = document.createElement('a')
  aLink.style.display = 'none'
  aLink.href = url
  aLink.setAttribute('download', filename)
  // 将a标签挂载上去
  document.body.appendChild(aLink)
  // a标签click事件
  aLink.click()
  // 移除a标签
  document.body.removeChild(aLink)
  // 销毁下载链接
  window.URL.revokeObjectURL(url)
  downloadLoadingInstance.close();
}
/**
 * 是否有权限
 * @param {*} key
 */
export function hasPermission (key) {
  let data =   this.$jwt.decode(window.sessionStorage.getItem('accessToken'))
  let permission = data["authorities"]
  return permission.indexOf(key) !== -1 || false
}
