import request from '@/utils/request'

export function getHome(params) {
  return request({
    url: '/system/api/home',
    headers: {
      repeatSubmit: true
    },
    method: 'get',
    params: params
  })
}


