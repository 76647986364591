<template>
  <div class="back">
  <el-form :rules="rules" v-loading="loading" element-loading-text="正在登陆"
           element-loading-spinner="el-icon-loading"
           element-loading-background="rgba(0, 0, 0, 0.8)"
           ref="login" :model="loginForm" class="loginContainer">
        <h3 class="loginTitle">系统登陆</h3>
        <el-tabs :stretch="true"  v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="账号密码登录" name="tab1" >
            <el-row>
              <el-col :span="24">
                <el-form-item prop="username">
                  <el-input type="text" auto-complete="false" v-model="loginForm.username" placeholder="请输入用户名"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item prop="password">
                  <el-input type="password" auto-complete="false" v-model="loginForm.password" placeholder="请输入密码"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="15">
                <el-form-item prop="vc_code">
                  <el-input type="text" auto-complete="false" v-model="loginForm.vc_code" placeholder="请输入验证码"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="9">
                <el-form-item style="padding: 0 0 0 5px;width: 100%">
                  <el-image :src="'data:image/*;base64,' + captchaUrl" @click="getCode" style="width: 85%"/>
                </el-form-item>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="手机验证码" name="tab2" >
            <el-row>
              <el-col :span="24">
                <el-form-item prop="phone">
                  <el-input type="text" auto-complete="false" v-model="phoneForm.phone" placeholder="请输入手机号"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="16">
                <el-form-item prop="vc_code">
                  <el-input type="text" auto-complete="false" v-model="phoneForm.vc_code" placeholder="请获取验证码"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <!-- 显示剩余秒数 -->
                <el-form-item style="padding: 0 0 0 5px;width: 100%">
                  <el-button v-if="codeShow" type="info" plain @click="getSmsCode">{{ codeText }}</el-button>
                  <el-button v-if="!codeShow" style="width: 100%;padding: 13px 0;font-size: 13px; margin-left: 0px" disabled>{{ codeText }}</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>
        <el-row>
          <el-col :span="24">
            <el-button type="primary" style="width: 100%" @click="submitLogin">登陆</el-button>
          </el-col>
        </el-row>
        <el-divider >其他方式登录</el-divider>
        <el-button type="success" icon="el-icon-check" circle></el-button>
    </el-form>
  </div>
</template>

<script>
    import {getKaptcha,getSmsCode} from "@/api/gateway/gateway";
    import {doLogin, doMobile} from "@/api/oauht/oauth";
    import Treeselect from "@riophae/vue-treeselect";
    import OauthCode from "@/views/login/OauthCode.vue";
    export default {
        name: "Login",
      components: {Treeselect},
        data(){
          return {
            codeShow: true,
            codeText: '获取验证码',
            activeName:'tab1',
            captchaUrl: '',
            loginForm:{
              username: '',
              password: '',
              vc_code: '',
              clientType: 'pc',
              auth_type: 'code',
              uuid: '',
            },
            phoneForm:{
              phone: '',
              vc_code: '',
              auth_type: 'sms_code',
              uuid: '',
            },
              code:{
                response_type: 'code',
                client_id: 'pc',
                scope: 'all',
              },
              loading:false,
              checked: true,
              rules:{
                  username: [{required:true,message:'请输入用户名',trigger:'blur'}],
                  password: [{required:true,message:'请输入密码',trigger:'blur'}],
                  code: [{required:true,message:'请输入验证码',trigger:'blur'}],
              }
          }
        },
      created() {
        this.getCode()
      },
      methods:{
        handleClick(key) {
          this.activeName = key.name
        },
        getCode() {
          getKaptcha().then(response => {
            this.captchaUrl = response.data.image
            this.loginForm.uuid = response.data.uuid
          });
        },
        getSmsCode(){
          if (this.phoneForm.phone==undefined||this.phoneForm.phone==""){
            this.$message({
              message: "手机号不能为空",
              type: 'error'
            });
            return;
          }
          getSmsCode(this.phoneForm.phone).then(resp=>{
            if (resp.success){
              this.phoneForm.uuid = resp.data.uuid
              if ("OK"==resp.data.msg){
                this.codeShow = false
                this.countDown(60)
              }else {
                this.$message({
                  message: resp.data.msg,
                  type: 'error'
                });
              }
            }
          })
        },
        // 倒计时方法
        countDown (time) {
          if (time === 0) {
            this.codeText = "获取验证码"
            this.codeShow= true
            return
          } else {
            this.codeText = time+"秒后重试"
            time--
          }
          setTimeout(()=> {
            this.countDown(time)
          }, 1000)
        },
        submitLogin(){
          if ("tab1"== this.activeName){
            this.$refs.login.validate((valid) => {
              if (valid) {
                this.loading=false
                doLogin(this.loginForm).then(resp=>{
                  this.loading=true
                  if (resp!==undefined && resp.success){
                    window.location.href = process.env.VUE_APP_SERVER_DOMAIN+'oauth/oauth/authorize?response_type=code&client_id=pc'
                  }else {
                    this.loading=false
                    this.$message({
                      message: resp.msg,
                      type: 'error'
                    });
                    this.$router.replace('/')
                  }
                })
              } else {
                this.$message({
                  message: '登录失败',
                  type: 'error'
                });
                return false;
              }
            });
          }else if ("tab2"== this.activeName){
            if (this.phoneForm.phone==undefined||this.phoneForm.phone==""){
              this.$message({
                message: "手机号不能为空",
                type: 'error'
              });
              return;
            }
            if (this.phoneForm.vc_code==undefined||this.phoneForm.vc_code==""){
              this.$message({
                message: "验证码不能为空",
                type: 'error'
              });
              return;
            }
            this.loading=false
            doMobile(this.phoneForm).then(resp=>{
              this.loading=true
              if (resp!==undefined && resp.success){
                window.location.href = process.env.VUE_APP_SERVER_DOMAIN+'oauth/oauth/authorize?response_type=code&client_id=pc'
              }else {
                this.loading=false
                this.$message({
                  message: resp.msg,
                  type: 'error'
                });
                this.$router.replace('/')
              }
            })
          }
        }
      }
  }
</script>

<style>
    .back{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      background-image: url("../../assets/login-background.gif");
      background-size: cover;
    }
    .loginContainer{
        border-radius: 15px;
        background-clip: padding-box;
        margin: 180px auto;
        width: 350px;
        padding: 15px 35px 15px 35px;
        background-color: rgba(229, 221, 221, 0.1);
        border: 1px solid #624f4f;
        box-shadow: 0 0 25px #7da2c4;
    }
    .loginTitle{
      margin: 0px auto 40px auto;
      text-align: center;
    }
    .back .el-tabs__item.is-active {
      color: #030303;
    }

    .back .el-tabs__item {
      color: #020202;
    }

</style>