import Vue from 'vue'
import VueRouter  from 'vue-router'
import Login from "../views/login/Login";
import OauthCode from "../views/login/OauthCode";


Vue.use(VueRouter)

export const routes = [
  {
    path: '/',
    component: Login,
    hidden:true,
  },
  {
    path: '/OauthCode',
    component: OauthCode,
    hidden:true
  }
]

export const router = new VueRouter({
  scrollBehavior: () => ({ y: 0 }),
  routes
})

export default router
