import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'font-awesome/css/font-awesome.css'
import {hasPermission,downloadPost,downloadGet} from "./utils/request";
import {handleTree } from "@/utils/tree";

// 自定义表格工具组件
import RightToolbar from "@/components/RightToolbar";
import Pagination from "@/components/Pagination";
import less from "less"
import {initMenu} from "./utils/menus";
import  jwt  from  'jsonwebtoken'
import '@/styles/index.scss'
import '@/icons'
import Tinymce from '@/components/tinymce/index.vue'
import modal from '@/plugins/modal'
import cache from "@/plugins/cache";

import DictTag from '@/components/DictTag'

import { addDateRange,parseTime} from "@/utils/ws";

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(less)
Vue.prototype.$jwt=jwt
Vue.prototype.$modal = modal
Vue.prototype.$cache = cache
Vue.prototype.hasPermission=hasPermission
Vue.prototype.$downloadGet = downloadGet
Vue.prototype.$downloadPost = downloadPost
Vue.prototype.handleTree = handleTree
Vue.prototype.addDateRange = addDateRange
Vue.prototype.parseTime = parseTime
Vue.component('RightToolbar', RightToolbar)
Vue.component('Pagination', Pagination)
Vue.component('tinymce', Tinymce)
Vue.component('DictTag', DictTag)

router.beforeEach((to, from, next)=>{
  if (window.sessionStorage.getItem('accessToken')){
    if (to.path=='/'){
      window.sessionStorage.removeItem('accessToken');
      window.sessionStorage.removeItem('refreshToken')
      window.sessionStorage.removeItem('user')
    }else {
      initMenu(router,store)
    }
    next();
  }else{
    if (to.path=='/'){
      next();
    }else if(to.path == "/OauthCode"){
      next();
    }else {
      next('/');
    }
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')



