import request from '@/utils/request'

export function loginOut(params) {
  return request({
    url: '/oauth/logout',
    method: 'get',
    params: params
  })
}
export function doLogin(params) {
  return request({
    url: '/oauth/doLogin',
    headers: {
      repeatSubmit: true,
      isToken: false
    },
    method: 'post',
    params: params
  })
}

export function doMobile(params) {
  return request({
    url: '/oauth/authentication/mobile',
    headers: {
      repeatSubmit: true
    },
    method: 'post',
    params: params
  })
}

export function getToken(params) {
  return request({
    url: '/oauth/oauth/token',
    headers: {
      repeatSubmit: true
    },
    method: 'post',
    params: params
  })
}

