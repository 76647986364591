<template>
  <div class="first-loading-wrp">
    <div class="loading-wrp">
        <span class="dot dot-spin">
          <i></i>
          <i></i>
          <i></i>
          <i></i>
        </span>
    </div>
    <h2>努力加载中...</h2>
  </div>
</template>

<script>
import {getToken} from "@/api/oauht/oauth";
export default {
    name: "OauthCode",
    data(){
      return {
        form: {
          code: '',
          client_id: 'pc',
          client_secret: '123456',
          grant_type: 'authorization_code',
        },
        token: '',
      }
    },
    created() {
      this.getCode()
    },
    methods:{
      getCode() {
        this.form.code = this.findUrl();
        //根据授权码获取token
        getToken(this.form).then(resp=>{

          if (resp==undefined){
            this.$router.replace('/')
            return;
          }
          window.sessionStorage.setItem('accessToken',resp.data.access_token)
          window.sessionStorage.setItem('refreshToken',resp.data.refresh_token)
          //清除地址栏的授权码
          let url = this.getNewUrl()
          window.history.replaceState(null, null, url)
          this.$router.replace('/home')
        })
      },
      findUrl(){
        const query = location.search.substring(1);//获取跳转路径参数字符串，去掉问号
        if (query==''){
          this.$router.replace('/')
        }
        const reg = new RegExp(`(^|&)code=([^&]*)(&|$)`);// 匹配id参数的正则表达式
        const code = query.match(reg);// 获取跳转路径参数id的值  //BuS0wa9Ov
        if (code==undefined||code==null){
          this.$router.replace('/')
        }
        const oauthCode = code[2]
        if (oauthCode==""){
          this.$router.replace('/')
        }
        return oauthCode;
      },
      getNewUrl () {
        let url = document.location.href;
        let reg = /[^\w](code=)=?([^&|^#]*)/g;
        return url.replace(reg, "");
      }
    }
}
</script>

<style type="text/css">
.first-loading-wrp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
  min-height: 90vh;
}

.first-loading-wrp>h1 {
  font-size: 30px;
  font-weight: bolder;
}

.first-loading-wrp .loading-wrp {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 98px;
}

.dot {
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  width: 64px;
  height: 64px;
  font-size: 64px;
  transform: rotate(45deg);
  animation: antRotate 1.2s infinite linear;
}

.dot i {
  position: absolute;
  display: block;
  width: 28px;
  height: 28px;
  background-color: #1890ff;
  border-radius: 100%;
  opacity: 0.3;
  transform: scale(0.75);
  transform-origin: 50% 50%;
  animation: antSpinMove 1s infinite linear alternate;
}

.dot i:nth-child(1) {
  top: 0;
  left: 0;
}

.dot i:nth-child(2) {
  top: 0;
  right: 0;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.dot i:nth-child(3) {
  right: 0;
  bottom: 0;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.dot i:nth-child(4) {
  bottom: 0;
  left: 0;
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

@keyframes antRotate {
  to {
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg);
  }
}

@-webkit-keyframes antRotate {
  to {
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg);
  }
}

@keyframes antSpinMove {
  to {
    opacity: 1;
  }
}

@-webkit-keyframes antSpinMove {
  to {
    opacity: 1;
  }
}
</style>