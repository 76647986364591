import {getHome} from "@/api/system/system"
import store from "@/store";
export const initMenu = (router,store)=>{
    if (store.state.routes.length>0){
        return;
    }
    getHome().then(data=>{
        if (data){
            //格式化router
            let fmtRoutes = formatRoutes(data.data.sysPermission);
            router.addRoutes(fmtRoutes)
            //将树据存入vuex中
            store.commit('initRoutes',fmtRoutes)
            store.commit('user',data.data.sysUser)
        }
    })
}
export const formatRoutes = (routes)=>{
    let fmtRoutes = [];
    routes.forEach(router=>{
        let {
            url,
            component,
            iconCls,
            name,
            children,
        }=router
        if (children instanceof Array){
            children = formatRoutes(children)
        }
        let fmRouter = {
            path: url,
            name: name,
            iconCls: iconCls,
            children: children,
            component(resolve){
                if (component.startsWith('Home')){
                    require(['../views/home/Home'], resolve);
                }else {
                    require(['../views/'+component], resolve);
                }
            }
        }
        fmtRoutes.push(fmRouter)
    });
    return fmtRoutes;
}