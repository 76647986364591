import Vue from "vue";
import Vuex from "vuex";
import de from "element-ui/src/locale/lang/de";
Vue.use(Vuex);
export default new Vuex.Store({
    //这个就是数据
    state: {
        routes:[],
        config:{},
        user:{}
    },
    //相当于组件的计算属性
    getters: {

    },
    //这个里面可以更新state的数据不过是同步的
    mutations:{
        initRoutes(state,data){
            state.routes=data
        },
        config(state,data){
            state.config=data
        },
        user(state,data){
            state.user=data
        },
    },
    //异步请求可以在这个里面实现
    actions:{},
    //这个可以引入多个模块
    modules: {}
})