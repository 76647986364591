import request from '@/utils/request'

export function getKaptcha(data) {
  return request({
    url: '/gateway/kaptcha',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}
export function getSmsCode(query) {
  return request({
    url: '/gateway/smsCode',
    headers: {
      isToken: false
    },
    method: 'get',
    params: {phone:query}
  })
}
